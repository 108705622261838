import {inject,bindable } from 'aurelia-framework';
import { ProfileService } from '../../../../shared/services/profielservice';
import { JwtService } from '../../../../shared/services/jwtservice';
import { DialogController } from 'aurelia-dialog';
import flatpickr from "flatpickr";
import {ValidationControllerFactory, ValidationRules} from "aurelia-validation";
import {config} from "../../../../shared/services/config";
import Scrollbar from 'smooth-scrollbar';
import {LoginService} from "../../../../shared/services/loginservice";
import { Router } from 'aurelia-router';

@inject(DialogController,ProfileService,JwtService,ValidationControllerFactory,Router,LoginService)

export class EditCTCComponent {
    fixedPay = '';
    variablePay = '';
    revisedCTC = '₹0';
    dropdownOptions = [];
    showEmployeeDropdown = false;
    selectedOption = null;
    selectedEmployees = [];
    showEmpDropdown = false;
    EmployeeListIDs = [];
    EmployeeList = [];
    EmployeeListNames = [];
    AddedEmployeeId = [];
    isDisabled = true;
    isDragging = false;
    dialog;
    static inject = [Element];
    constructor(controller,ProfileService,JwtService,controllerFactory,router,loginService) {
        this.controller = controller;
        this.profileService=ProfileService;
        this.jwtService= JwtService;
        this.router = router;
        this.loginService = loginService;
        this.empid = this.jwtService.getEmployeeId();
        this.controllerFactory = controllerFactory.createForCurrentScope();

        ValidationRules.customRule(
            'file',
            () =>{
                if(this.fileValidatorRule === true){
                    return false;
                }else{
                    return true;
                }
            },
            '${$displayName} is required'
        );

        ValidationRules
            .ensure('ApprasialDate').required().withMessage("The Apprasial Date is required")
            .ensure('selecteddepartment').required().withMessage("The department field is required.")
            .ensure('selectedposition').required().withMessage("The position field is required.")
            .ensure('selectedpositionlevel').required().withMessage("The position level field is required.")
            .ensure('AppraiseeName').required().withMessage("The Appraiser Name is required")
            .ensure('searchQuery').required().withMessage("The authorized by field is required.")
            .ensure('upload')
            .satisfiesRule('file')
            .on(this);
    }
    async bind() {
       setTimeout(()=>{       
         Scrollbar.init(document.querySelector('.editctc-scrollbar'));
       },1000)
    }
    async  attached(){

        flatpickr(this.ApprasialDatePicker, {
            closeOnSelect:true,
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate: this.dateValue,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,
            onClose: function (selectedDates, dateStr, instance) {
                this.ApprasialDate=dateStr;
            }
        });
        this.apprasialdateIcon.addEventListener('click', () => {
            this.ApprasialDatePicker._flatpickr.open();
        });
        flatpickr(this.EffectivedatePicker, {
            closeOnSelect:true,
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate: this.dateValue,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,
            onClose: function (selectedDates, dateStr, instance) {
                this.EffectiveDate=dateStr;
            }
        });
        this.effectiveIcon.addEventListener('click', () => {
            this.EffectivedatePicker._flatpickr.open();
        });
        flatpickr(this.duedatePicker, {
            closeOnSelect:true,
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate: this.dateValue,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,
            onClose: function (selectedDates, dateStr, instance) {
                this.DueDate=dateStr;
            }
        });
        this.duedateIcon.addEventListener('click', () => {
            this.duedatePicker._flatpickr.open();
        });
        this.profileService.DepartmentDropdown()
            .then(data => {
                this.departments= data;
            });
        this.profileService.PositionDropdown()
            .then(data => {
                this.postions= data;
            });
        this.profileService.PositionLevelDropdown()
            .then(data => {
                this.positionlevels= data;
            });
        this.fileValidator();
    }
    departmentMatcher = (a, b) => {
        return a.key === b.key;
    };
    positionMatcher = (a, b) => {
        return a.key === b.key;
    };
    positionlevelMatcher = (a, b) => {
        return a.key === b.key;
    };
    returnDateSelected() {
        this.isDisabled = false;
    }
    updateFormattedValue(property) {
        const fixedPayValue = parseFloat(this.fixedPay) || 0;
        const variablePayValue = parseFloat(this.variablePay) || 0;
        const sum = fixedPayValue + variablePayValue;
        const formattedSum = sum.toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR'
        });
        this.revisedCTC = formattedSum || '₹0'; // Set revisedCTC to zero if it's empty
    }
    cancel() {
        this.controller.cancel();
    }
    async selectposition(data){
        const position = await this.profileService.PositionDropdown();
        const positionid = data.DepartmentId ? data.DepartmentId : data;
        this.positiondropdowns = position.filter(option => option.DepartmentID === positionid);
    }
    async selectpositionlevel(data){
        const positionlevels = await this.profileService.PositionLevelDropdown();
        const positionlevelids = data.PositionID ? data.PositionID : data;
        this.positionleveldropdowns = positionlevels.filter(option => option.PositionID === positionlevelids);
    }
    togglePositionFields(checked){
        this.showPositionFields = checked;
        flatpickr(this.Effectivedate2Picker, {
            closeOnSelect:true,
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate: this.dateValue,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,
            onClose: function (selectedDates, dateStr, instance) {
                this.EffectiveDate2=dateStr;
            }
        });
        this.effective2Icon.addEventListener('click', () => {
            this.Effectivedate2Picker._flatpickr.open();
        });
    }
    async onAppraiserNameSelected() {
        if (this.AppraiseeName) {
            this.showEmployeeDropdown = true;
            try {
                const response = await this.profileService.EmployeeDropdown(this.AppraiseeName);
                const data = await response;
                const filterOption = this.AppraiseeName.toLowerCase();
                this.dropdownOptionss = data.filter(empname => empname.EmployeeName.toLowerCase().includes(filterOption));
                if (this.dropdownOptionss.length === 0) {
                    const errorInfo = {
                        error: {
                            message: "Please select valid employee"
                        }
                    };
                    this.NameError = [errorInfo];
                } else {
                    this.NameError = [];
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            this.showEmployeeDropdown = false;
            this.dropdownOptionss = [];
        }
    }
    selectEmployee(EmpOption) {
        const modifiedOption = { ...EmpOption, ID: EmpOption.EmployeeID };
        this.AppraiseeName = modifiedOption.EmployeeName;
        this.AppraiseeID = modifiedOption.EmployeeID;
        this.showEmployeeDropdown = false;
        this.selectedEmployee = modifiedOption;
    }
    async onEmployeeSelected() {
        if (this.searchQuery) {
            this.showEmpDropdown = true;
            try {
                const response = await this.profileService.EmployeeDropdown(this.searchQuery);
                const data = await response;
                const filterOption = this.searchQuery.toLowerCase();
                this.dropdownOptions = data.filter(option => option.EmployeeName.toLowerCase().includes(filterOption));
            } catch (error) {
                console.error(error);
            }
        } else {
            this.showEmpDropdown = false;
            this.dropdownOptions = [];
        }
    }
    selectaddEmployee(EmpOption) {
        const modifiedOption = {
            ...EmpOption,
            ID: EmpOption.EmployeeID
        };
        this.searchQuery = modifiedOption.EmployeeName;
        this.showEmpDropdown = false;
        this.selectedEmployee = modifiedOption;
        return false;
    }
    addToSelectedOptions(id) {
        if (!this.searchQuery) {
            const errorInfo = {
                error: {
                    message: "Please select an employee"
                }
            };
            this.searchQueryError = [errorInfo];
            return;
        }
        if (this.AddedEmployeeId.includes(id)) {
            const errorInfo = {
                error: {
                    message: "Employee name already exists"
                }
            };
            this.searchQueryError = [errorInfo];
            return;
        }
        this.profileService.GetEmployeeNamesForGrid(id)
            .then(data => {
                this.AddedEmployeeId.push(data.EmployeeID);
                this.profileService.GetEmployeeNamesForGrid(data.EmployeeID)
                    .then(data => {
                        this.EmployeeListNames.push(data.EmployeeName);
                    });
                this.searchQuery = '';
            });
    }
    deleteEmployee(emp) {
        const index = this.EmployeeListNames.indexOf(emp);
        if (index !== -1) {
            this.EmployeeListNames.splice(index, 1);
        }
        const deletedIds = emp.EmployeeID;
        this.AddedEmployeeId = this.AddedEmployeeId.filter(id => id !== deletedIds);
        this.Ids = this.AddedEmployeeId;
    }
    handleFileSelect(files) {
        const file = files[0];
        this.file = file;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        this.DocumentName = file.name;
        reader.onload = () => {
            this.file = reader.result;
        };
    }
    fileValidator(){
        const fileInput = document.querySelector('input[type="file"]');
        const allowedTypes = ['image/jpeg', 'image/png','application/msword','application/pdf'];
        fileInput.addEventListener('change', (event) => {
            const file = event.target.files[0];
            const fileType = file.type;
            if (!allowedTypes.includes(fileType)) {
                this.fileMessage="Only JPEG, PNG, PDF and DOC files are allowed.";
                this.fileValidatorRule = true;
            } else {
                this.fileMessage=null;
                this.fileValidatorRule = false;
            }
        });
    }
    openFileDialog() {
        document.getElementById('attachments').click();
    }
    downloadFile() {
        const downloadUrl = config.api_img_url + this.file;
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.click();
    }
    removeFile() {
        this.file = null;
        this.DocumentName = '';
        const fileInput = document.getElementById('attachments');
        if (fileInput) {
            fileInput.value = ''; // Clear the file input value
        }
    }
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    }

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }
    async activate(data){
        this.employeectc = data;
        this.ApprasialDate = data.DateofAppraisal;
        this.employeeList = data.AuthorizeeList;
        this.revisedCTC = data.RevisedCTC;
        this.departmentname = data.DepartmentId;
        this.positionname = data.PositionId;
        this.positionlevelname = data.PositionLevelId;
        this.comments = data.Reason;
        this.variablePay = data.VariablePay;
        this.fixedPay = data.TotalPay;
        this.ispositionlevel = data.PositionLevelIND;
        if( this.ispositionlevel === 1){
            this.ispositionlevel = true;
        }else if(this.ispositionlevel === 0){
            this.ispositionlevel = false;
        }
        this.DueDate = data.NextRevisionDueDate;
        this.EffectiveDate2 = data.PositionlevelEffectiveDate;
        this.EffectiveDate = data.RevisedCTCEffectiveDate;
        this.remaindBefore = data.DaysToRemindsHR;
        this.SalaryRevisionId = data.SalaryRevisionId;
        this.AppraiseeName = data.PositionLevelName;
        this.DocumentName = data.AppraisalLetterName;
        this.AppraisalLetter = data.AppraisalLetter;
        if (data.AppraisalLetter) {
            this.file = data.AppraisalLetter;
            this.DocumentName = data.AppraisalLetter;
            this.DocumentNameDisplay = data.AppraisalLetterName;
        }
        this.employeeList.filter(response => {
            this.profileService.GetEmployeeNamesForGrid(response).then(
                data => {
                    this.EmployeeListNames.push(data.EmployeeName);
                }
            )
        });
        await this.selectposition(this.departmentname);
        await this.selectpositionlevel(this.positionname);
    }
    save() {
        const refreshEvent = new CustomEvent('refreshCTCEdit');
        this.controllerFactory.validate()
            .then(result => {
                if (result.valid) {
                    let data = {
                        EmployeeID: this.empid,
                        DateofAppraisal: this.ApprasialDate,
                        AppraiseeId: this.AppraiseeID,
                        RevisedCTC: this.revisedCTC,
                        DepartmentId: this.selecteddepartment ? this.selecteddepartment.DepartmentId : this.departmentname,
                        PositionID:this.selectedposition ? this.selectedposition.PositionID : this.positionname,
                        PositionLevelIND: this.ispositionlevel ? 1 : 0,
                        PositionLevelId:this.selectedpositionlevel ? this.selectedpositionlevel.PositionLevelId : this.positionlevelname,
                        Reason:this.comments,
                        NextRevisionDueDate:this.DueDate,
                        PositionlevelEffectiveDate:this.EffectiveDate2,
                        RevisedCTCEffectiveDate:this.EffectiveDate,
                        DaysToRemindsHR:this.remaindBefore,
                        VariablePay:this.variablePay,
                        TotalPay:this.fixedPay,
                        AuthorizeeList:this.AddedEmployeeId,
                        AppraisalLetter:this.file,
                        AppraisalLetterName:this.DocumentName,
                        SalaryRevisionId:this.SalaryRevisionId,

                    };
                    this.profileService.SaveCTCData(data)
                        .then((response) => {
                            this.controller.cancel();
                            this.profileService.GetEmployeeCTCData(this.empid)
                                .then(data => {
                                    this.employeectc = data;
                                    document.dispatchEvent(refreshEvent);
                                });
                        });
                }
            });
    }
}

