import {inject,bindable } from 'aurelia-framework';
import { ProfileService } from '../../../../shared/services/profielservice';
import { JwtService } from '../../../../shared/services/jwtservice';
import { DialogController } from 'aurelia-dialog';
import flatpickr from "flatpickr";
import {ValidationControllerFactory, ValidationRules} from "aurelia-validation";
import Scrollbar from 'smooth-scrollbar';
import {LoginService} from "../../../../shared/services/loginservice";
import { Router } from 'aurelia-router';

@inject(DialogController,ProfileService,JwtService,ValidationControllerFactory,Router,LoginService)

export class AddCTCComponent {
    fixedPay = '';
    variablePay = '';
    dropdownOptions = [];
    showEmployeeDropdown = false;
    selectedOption = null;
    selectedEmployees = [];
    showEmpDropdown = false;
    EmployeeListIDs = [];
    isDisabled = true;
    isDragging = false;
    dialog;
    static inject = [Element];
    constructor(controller,ProfileService,JwtService,controllerFactory,router,loginService) {
        this.controller = controller;
        this.profileService=ProfileService;
        this.jwtService= JwtService;
        this.router = router;
        this.loginService = loginService;
        this.empid = this.jwtService.getEmployeeId();
        this.controllerFactory = controllerFactory.createForCurrentScope();
        ValidationRules.customRule(
            'file',
            () =>{
                if(this.fileValidatorRule === true){
                    return false;
                }else{
                    return true;
                }
            },
            '${$displayName} is required'
        );

        ValidationRules
            .ensure('AppraisalDate').required()
            .ensure('departmentID').required().withMessage("The department field is required.")
            .ensure('positionID').required().withMessage("The position field is required.")
            .ensure('positionlevelID').required().withMessage("The position level field is required.")
            .ensure('AppraiserName').required().withMessage("The Appraiser Name is required")
            .ensure('upload')
            .satisfiesRule('file')
            .on(this);
    }
    async bind() {
       setTimeout(()=>{       
         Scrollbar.init(document.querySelector('.addctc-scrollbar'));
       },1000)
    }

    attached(){

        flatpickr(this.ApprasialDatePicker, {
            closeOnSelect:true,
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate: this.dateValue,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,
            onClose: function (selectedDates, dateStr, instance) {
                this.ApprasialDate=dateStr;
            }
        });
        this.apprasialdateIcon.addEventListener('click', () => {
            this.ApprasialDatePicker._flatpickr.open();
        });
        flatpickr(this.EffectivedatePicker, {
            closeOnSelect:true,
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate: this.dateValue,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,
            onClose: function (selectedDates, dateStr, instance) {
                this.EffectiveDate=dateStr;
            }
        });
        this.effectiveIcon.addEventListener('click', () => {
            this.EffectivedatePicker._flatpickr.open();
        });
        flatpickr(this.duedatePicker, {
            closeOnSelect:true,
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate: this.dateValue,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,
            onClose: function (selectedDates, dateStr, instance) {
                this.DueDate=dateStr;
            }
        });
        this.duedateIcon.addEventListener('click', () => {
            this.duedatePicker._flatpickr.open();
        });
        this.profileService.DepartmentDropdown()
            .then(data => {
                this.departments= data;
            });
        this.profileService.PositionDropdown()
            .then(data => {
                this.postions= data;
            });
        this.profileService.PositionLevelDropdown()
            .then(data => {
                this.positionlevels= data;
            });
        this.fileValidator();
    }
    returnDateSelected() {
        this.isDisabled = false;
    }
    updateFormattedValue(property) {
        const fixedPayValue = parseFloat(this.fixedPay.replace(/[^0-9.]/g, '')) || 0;
        const variablePayValue = parseFloat(this.variablePay.replace(/[^0-9.]/g, '')) || 0;
        const sum = fixedPayValue + variablePayValue;
        const formattedSum = sum.toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR'
        });
        this.revisedCTC = formattedSum; // Set revisedCTC to zero if it's empty
    }
    cancel() {
        this.controller.cancel();
    }
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    }

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }
    async selectposition(data){
        const position = await this.profileService.PositionDropdown();
        this.positiondropdowns = position.filter(option => option.DepartmentID == data);
    }
    async selectpositionlevel(data){
        const positionlevel = await this.profileService.PositionLevelDropdown();
        this.positionleveldropdowns = positionlevel.filter(option => option.PositionID == data);
    }
    togglePositionFields(checked){
        this.showPositionFields = checked;
        flatpickr(this.Effectivedate2Picker, {
            closeOnSelect:true,
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate: this.dateValue,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,
            onClose: function (selectedDates, dateStr, instance) {
                this.EffectiveDate2=dateStr;
            }
        });
        this.effective2Icon.addEventListener('click', () => {
            this.Effectivedate2Picker._flatpickr.open();
        });
    }
    async onAppraiserNameSelected() {
        if (this.AppraiserName) {
            this.showEmployeeDropdown = true;
            try {
                const response = await this.profileService.EmployeeDropdown(this.AppraiserName);
                const datas = await response;
                const filterOption = this.AppraiserName.toLowerCase();
                this.dropdownOptionss = datas.filter(empname => empname.EmployeeName.toLowerCase().includes(filterOption));
                if (this.dropdownOptionss.length === 0) {
                    const errorInfo = {
                        error: {
                            message: "Please select valid employee"
                        }
                    };
                    this.AppraiserNameError = [errorInfo];
                } else {
                    this.AppraiserNameError = [];
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            this.showEmployeeDropdown = false;
            this.dropdownOptionss = [];
        }
    }
    selectEmployee(EmpOptions) {
        const modifiedOption = { ...EmpOptions, ID: EmpOptions.EmployeeID };
        this.AppraiserName = modifiedOption.EmployeeName;
        this.AppraiserID = modifiedOption.EmployeeID;
        this.showEmployeeDropdown = false;
        this.selectedEmployee = modifiedOption;
    }
    async onEmployeeSelected() {
        if (this.searchQuery) {
            this.showEmpDropdown = true;
            try {
                const response = await this.profileService.EmployeeDropdown(this.searchQuery);
                const data = await response;
                const filterOption = this.searchQuery.toLowerCase();
                this.dropdownOptions = data.filter(option => option.EmployeeName.toLowerCase().includes(filterOption));
                if (this.dropdownOptions.length === 0) {
                    const errorInfo = {
                        error: {
                            message: "Please select valid employee"
                        }
                    };
                    this.searchQueryError = [errorInfo];
                } else {
                    this.searchQueryError = [];
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            this.showEmpDropdown = false;
            this.dropdownOptions = [];
        }
    }
    selectaddEmployee(EmpOption) {
        const modifiedOption = {...EmpOption, ID: EmpOption.EmployeeID};
        this.searchQuery = modifiedOption.EmployeeName;
        this.showEmpDropdown = false;
        this.selectedEmployee = modifiedOption;
        return false;
    }
    addToSelectedOptions(id) {
        if (!this.searchQuery) {
            const errorInfo = {
                error: {
                    message: "Please select an employee"
                }
            };
            this.searchQueryError = [errorInfo];
            return;
        }
        this.profileService.GetEmployeesForAdd(id).then(data => {
            const selectedEmployee = this.selectedEmployees.find(employee => employee.EmployeeID === data.EmployeeID);
            if (selectedEmployee) {
                const errorInfo = {
                    error: {
                        message: "Employee name already exists"
                    }
                };
                this.searchQueryError = [errorInfo];
            } else {
                this.searchQueryError = [];
                this.Ids = data.EmployeeID;
                this.EmployeeListIDs.push(this.Ids);
                this.selectedEmployees.push(data);
                this.searchQuery = '';
            }
        });
    }

    deleteEmployee(emp) {
        const index = this.selectedEmployees.indexOf(emp);
        if (index !== -1) {
            this.selectedEmployees.splice(index, 1);
        }
        const deletedIds = emp.EmployeeID;
        this.EmployeeListIDs = this.EmployeeListIDs.filter(id => id !== deletedIds);
        this.Ids = this.EmployeeListIDs;
    }
    handleFileSelect(files) {
        const file = files[0];
        this.file = file;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        this.DocumentName = file.name;
        reader.onload = () => {
            this.file = reader.result;
        };
    }
    fileValidator(){
        const fileInput = document.querySelector('input[type="file"]');
        const allowedTypes = ['image/jpeg', 'image/png','application/msword','application/pdf'];
        fileInput.addEventListener('change', (event) => {
            const file = event.target.files[0];
            const fileType = file.type;
            if (!allowedTypes.includes(fileType)) {
                this.fileMessage="Only JPEG, PNG, PDF and DOC files are allowed.";
                this.fileValidatorRule = true;
            } else {
                this.fileMessage=null;
                this.fileValidatorRule = false;
            }
        });
    }
    openFileDialog() {
        document.getElementById('attachments').click();
    }
    removeFile() {
        this.file = null;
        this.DocumentName = '';
        const fileInput = document.getElementById('attachments');
        if (fileInput) {
            fileInput.value = ''; // Clear the file input value
        }
    }
    save() {
        const refreshEvent = new CustomEvent('refreshCTCADD');
        this.controllerFactory.validate()
            .then(result => {
                if (result.valid) {
                    let data = {
                    EmployeeID: this.empid,
                    DateofAppraisal: this.AppraisalDate,
                    AppraiseeId: this.AppraiserID,
                    RevisedCTC: this.revisedCTC,
                    DepartmentId: this.departmentID,
                     PositionLevelIND: this.PositionLevelIND ? 1 : 0,
                    PositionId:this.positionID,
                    PositionLevelId:this.positionlevelID,
                    Reason:this.comments,
                    NextRevisionDueDate:this.dueDate,
                    PositionlevelEffectiveDate:this.EffectiveDate2,
                    RevisedCTCEffectiveDate:this.EffectiveDate,
                    DaysToRemindsHR:this.remaindBefore,
                    VariablePay:this.variablePay,
                    TotalPay:this.fixedPay,
                    AuthorizeeList:this.EmployeeListIDs,
                    AppraisalLetter:this.file,
                    AppraisalLetterName:this.DocumentName,
                    };
                    this.profileService.SaveCTCData(data)
                        .then(() => {
                            this.controller.cancel();
                            this.profileService.GetEmployeeCTCData(this.empid)
                                .then(data => {
                                    this.employeectc = data;
                                    document.dispatchEvent(refreshEvent);
                                     });
                        });
                }
            });
    }
}

