import {inject } from 'aurelia-framework';
import { ProfileService } from '../../../shared/services/profielservice';
import { JwtService } from '../../../shared/services/jwtservice';
import {DialogService} from 'aurelia-dialog';
import {AlertpopupComponent} from "../../alertpopup/alertpopupcomponent";
import {AddCTCComponent} from "./addnewctc/addctccomponent";
import {EditCTCComponent} from "./editctcreversion/editctccomponent";
import {config} from "../../../shared/services/config";
import { Router } from 'aurelia-router';
import Scrollbar from 'smooth-scrollbar';
import {LoginService} from "../../../shared/services/loginservice";

@inject(ProfileService,JwtService,DialogService,Router,LoginService)
export class Ctcrevisionhistory {
    sortConfig = {
        column: '',
        direction: 'asc'
    };
    pageSize = config.grid_PazeSize;
    deletemessage = false;
    contactmessage = false;

    constructor(profileService,jwtService,dialogService,router,loginService) {
        this.profileService = profileService;
        this.jwtService = jwtService;
        this.dialogService = dialogService;
        this.router = router;
        this.loginService = loginService;
        this.empid = this.jwtService.getEmployeeId();

    }
    async bind() {
       setTimeout(()=>{       
         Scrollbar.init(document.querySelector('.ctc-scrollbar'));
       },1000)
    }
    activate(){
        this.employeectc = this.GetEmployeeCTCDetailsADD();
        this.employeectc = this.GetEmployeeCTCDetailsEDIT();
    }
    sortBy(column) {
        if (this.sortConfig.column === column) {
            this.sortConfig.direction = this.sortConfig.direction === 'asc' ? 'desc' : 'asc';
        } else {
            this.sortConfig.column = column;
            this.sortConfig.direction = 'asc';
        }

        this.performSorting();
    }
    performSorting() {
        const { column, direction } = this.sortConfig;
        if (column) {
            this.employeectc.sort((a, b) => {
                const aValue = a[column];
                const bValue = b[column];
                let comparison = 0;
                if (typeof aValue === 'string' && typeof bValue === 'string') {
                    comparison = aValue.localeCompare(bValue);
                } else {
                    comparison = aValue - bValue;
                }
                return direction === 'asc' ? comparison : -comparison;
            });
        }
    }
    async attached(params) {

        const id = this.router.currentInstruction.queryParams.id;
            if(id != null && id !='' && id !=undefined)
            {
                this.empid=id;
            }
            else{
                this.empid=this.jwtService.getEmployeeId();
            }
            const employeeCTCData = await this.profileService.GetEmployeeCTCData(this.empid);
            this.employeectc = await employeeCTCData;
            const anotherobject = this.employeectc;
            anotherobject.filter(data => {
                this.FilePath = data.AppraisalLetter;
                if (data.AppraisalLetter) {
                this.file = data.AppraisalLetter;
                this.DocumentName = data.AppraisalLetter;
            }
        });
            document.addEventListener('refreshCTCADD', this.GetEmployeeCTCDetailsADD);
            document.addEventListener('refreshCTCEdit', this.GetEmployeeCTCDetailsEDIT);
    }
    async deleteEmployeeCTC(data) {
        const salaryreversionID = data.SalaryRevisionId;
        const result = await this.dialogService.open({
            viewModel: AlertpopupComponent,
            model: "",
        }).whenClosed(response => response);
        if (result.wasCancelled) {
            return;
        }
        const deletedata =  await this.profileService.DeleteEmployeeCTCData(salaryreversionID, this.empid);
        const deleteresponse = await deletedata;
        const employeeCTC = await this.profileService.GetEmployeeCTCData(this.empid);
        this.employeectc = employeeCTC;
        this.Deletesucess = "Employee CTC Reversion history Deleted successfully";
        this.deletemessage = true;
        setTimeout(() =>{
            this.Deletesucess = null;
        },3000)
    }
    AddnewCTCPopup(data) {
        this.dialogService.open({viewModel: AddCTCComponent, model: data});
    }
    EditCTCPopup(data) {
        this.dialogService.open({viewModel: EditCTCComponent, model: data});
    }
    GetEmployeeCTCDetailsADD = () => {
        this.profileService.GetEmployeeCTCData(this.empid)
            .then(data => {
                this.employeectc = data;
                this.contactsucess = "CTC / Position revision details added successfully.";
                this.contactmessage = true;
                setTimeout(() =>{
                    this.contactsucess = null;
                },3000)
            });
    };
    GetEmployeeCTCDetailsEDIT = () => {
        this.profileService.GetEmployeeCTCData(this.empid)
            .then(data => {
                this.employeectc = data;
                this.contactsucess = "CTC / Position revision details updated successfully.";
                this.contactmessage = true;
                setTimeout(() =>{
                    this.contactsucess = null;
                },3000)
            });
    };
    downloadFile() {
        const downloadUrl = config.api_img_url + this.FilePath;
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = this.FilePath;
        link.click();
    }
}